import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"

const About = () => (
  <Layout>
    <Seo title="Director's Message" />
    <Banner title="Director's Message" />
    <section id="services" className="single-features pb-5 pt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <p className="pt-2 pb-2 lead">
              Lappy Nation serves as All in One Destination, for giving assets
              to numerous IT organizations, Educational Institutes, Workspaces,
              Students, Professionals, and so forth. With a group of
              enthusiastic experts, Lappy Nation was set up with a dream to help
              individuals in bringing down their Expenses and Growing their
              Business. We constantly improve our items, investigating
              additional opportunities, new technologies in our business. We
              intend to take business advancement and consumer loyalty to an
              alternate level through our different administrations.
            </p>
            <p className="pt-2 pb-2 lead">
              Lappy Nation provides cost-effective rentals and believes that the
              greatest benefit of renting is less expensive than purchasing it,
              particularly if you just need it for a brief timeframe. Advisable
              to rent for a longer period as it provides less rental expenses of
              the same.
            </p>

            <p className="pt-2 pb-2 lead">
              The mission of Lappy Nation aims to plan the best activity and
              control strategies and advances and make critical commitments to
              the feasible improvement of our general public by offering
              reasonable rental services. We deeply rely on conceivable through
              testing the present and moving towards the future. We would like
              to extend our sincere gratitude to our clients, who keep on
              choosing us for their upcoming ventures and recommend us in their
              community.
            </p>
            <p className="pt-2 pb-2 text-right">
              <strong>Kushal Sharma</strong> <br />
              Founder and Managing Director <br />
              Lappy Nation
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default About
